<template>
    <div>
        <button class="create_but" @click="login_show_but">生成内容</button>
    </div>
</template>

<script>
import { goAi } from '@/util/goAi'
import { mapState } from 'vuex';
export default {
    compute: {
        ...mapState(['loginShow'])
    },
    data() {
        return {}
    },
    methods: {
        async login_show_but() {
            const aiStatus = await goAi()
            if (aiStatus == 'ok') {
                this.$emit('file_data')
            } else {
                return this.$store.state.loginShow = true
            }
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>